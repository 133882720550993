import React, {
    CSSProperties,
    useRef,
    createRef,
    useState,
    useEffect,
} from 'react';
import { rgba } from 'polished';
import { Sticky } from 'react-sticky';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import FontFaceObserver from 'fontfaceobserver';

import logo from '../images/logo.png';
import { FONT_GOTHAM_LIGHT } from '../styles/fonts';
import { getUrl } from '../utils';
import { HeaderBgColor, BG_COLORS, DEFAULT_ALT, COLORS } from '../constants';
import { useMenu } from '../queries/useMenu';
import { ReactComponent as SearchIcon } from '../images/search-icon.svg';

import MobileMenu from './MobileMenu';
import { device } from './commonStyled';
import Search from './Search';

interface HeaderProps {
    bgColor?: HeaderBgColor;
}

const Header: React.FC<HeaderProps> = ({ children, bgColor }) => {
    const [menuItemsWidth, setMenuItemsWidth] = useState([]);
    const [subMenuItemsWidth, setSubMenuItemsWidth] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const menus = useMenu();

    const menuItemRefs = useRef(menus.map(() => createRef<HTMLDivElement>()));
    const subMenuItemRefs = useRef(
        menus.map(() => createRef<HTMLDivElement>())
    );

    useEffect(() => {
        const setWidths = () => {
            const widths = menuItemRefs.current.map(
                (ref) => ref.current?.getBoundingClientRect().width
            );
            const subMenuWidths = subMenuItemRefs.current.map(
                (ref) => ref.current?.getBoundingClientRect().width
            );

            setMenuItemsWidth(widths);
            setSubMenuItemsWidth(subMenuWidths);
        };
        setWidths();

        const font = new FontFaceObserver(FONT_GOTHAM_LIGHT);
        font.load().then(() => {
            setWidths();
        });
    }, []);

    const handleSearchClick = () => {
        setIsSearchOpen(true);
    };

    return (
        <Container bgColor={bgColor}>
            <MobileMenu
                bgColor={bgColor}
                handleSearchClick={handleSearchClick}
            />
            <Sticky topOffset={50} style={{ zIndex: 10 }}>
                {({ style, isSticky }) => (
                    <NavBar isSticky={isSticky} bgColor={bgColor} style={style}>
                        <Link to="/" activeStyle={activeStylesMenuItem}>
                            <Logo
                                isSticky={isSticky}
                                src={logo}
                                alt={DEFAULT_ALT}
                            />
                        </Link>
                        <MenuContainer
                            itemScope
                            itemType="http://schema.org/SiteNavigationElement"
                        >
                            {menus.map((item, i) => (
                                <MenuItemWrap
                                    parent={!!item.child_items?.length}
                                    bgColor={bgColor}
                                    ref={menuItemRefs.current[i]}
                                    key={i}
                                >
                                    <MenuItemContainer itemProp="name">
                                        <MenuItem
                                            key={i}
                                            itemProp="url"
                                            {...(getUrl(item).isExternal
                                                ? {
                                                      href: getUrl(item).url,
                                                      target: '_blank',
                                                      rel: 'noopener',
                                                  }
                                                : {
                                                      to: getUrl(item).url,
                                                      activeStyle: activeStylesMenuItem,
                                                      partiallyActive: false,
                                                      as: Link,
                                                  })}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    </MenuItemContainer>
                                    {item.child_items?.length && (
                                        <MenuSubItemContainer
                                            bgColor={bgColor}
                                            minWidth={menuItemsWidth[i]}
                                            currentWidth={subMenuItemsWidth[i]}
                                            ref={subMenuItemRefs.current[i]}
                                        >
                                            {item.child_items.map(
                                                (subItem, index) => (
                                                    <MenuSubItem
                                                        key={index}
                                                        {...(getUrl(subItem)
                                                            .isExternal
                                                            ? {
                                                                  href: getUrl(
                                                                      subItem
                                                                  ).url,
                                                                  target:
                                                                      '_blank',
                                                                  rel:
                                                                      'noopener',
                                                              }
                                                            : {
                                                                  to: getUrl(
                                                                      subItem
                                                                  ).url,
                                                                  activeStyle: activeStylesMenuItem,
                                                                  partiallyActive: false,
                                                                  as: Link,
                                                              })}
                                                    >
                                                        {subItem.title}
                                                    </MenuSubItem>
                                                )
                                            )}
                                        </MenuSubItemContainer>
                                    )}
                                </MenuItemWrap>
                            ))}
                        </MenuContainer>
                        <SearchIconButton
                            title="поиск по сайту"
                            onClick={handleSearchClick}
                        >
                            <SearchIconStyled />
                        </SearchIconButton>
                    </NavBar>
                )}
            </Sticky>
            {children}
            <Search
                isOpen={isSearchOpen}
                onClose={() => setIsSearchOpen(false)}
            />
        </Container>
    );
};

export default Header;

const SearchIconButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`;

const SearchIconStyled = styled(SearchIcon)`
    width: 25px;
    height: auto;
    fill: #dcb972;
`;

const Logo = styled.img<{ isSticky: boolean }>`
    width: 64px;

    transform: ${({ isSticky }) =>
        isSticky ? 'scale(0.8) translateX(4px)' : 'scale(1)'};

    transition: all 0.2s ease-in-out;

    @media ${device.laptopLarge} {
        width: 45px;
    }
`;

const activeStylesMenuItem: CSSProperties = {
    color: '#A60424',
    pointerEvents: 'none',
};

const MenuItemStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 16px;
    color: #000000;
    white-space: nowrap;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 22px;
    font-family: ${FONT_GOTHAM_LIGHT};
    transition: all 0.2s ease-in-out;
    z-index: 1000;

    &:hover {
        border: 1px solid #a60424;
        border-radius: 33px;
        color: #000000;
    }
`;

const MenuItemContainer = styled.div`
    position: relative;
    z-index: 1000;
`;

const MenuItem = styled('a')`
    ${MenuItemStyles}
`;

const MenuSubItemContainer = styled('div')<{
    bgColor: HeaderBgColor;
    minWidth: number;
    currentWidth: number;
}>`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 999;
    top: 47px;
    left: 0;
    border-radius: ${({ currentWidth, minWidth }) =>
        currentWidth <= minWidth ? '0 0 22px 22px' : '0 22px 22px 22px'};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
    overflow: visible;
    background-color: ${({ bgColor }) =>
        bgColor ? BG_COLORS[bgColor] : '#fff'};
    box-shadow: 9px 7px 65px 0 #d8cece;
`;

const MenuSubItem = styled('a')`
    ${MenuItemStyles}
    color: #8C8887;
    justify-content: flex-start;
`;

const MenuItemWrap = styled.div<{ parent?: boolean; bgColor: HeaderBgColor }>`
    position: relative;
    z-index: 9999;

    ${MenuItemContainer}:after {
        content: '';
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        ${({ parent, bgColor }) =>
            parent &&
            css`
                ${MenuItemContainer}:after {
                    content: '';
                    position: absolute;
                    visibility: visible;
                    opacity: 1;
                    top: 0;
                    left: 0;
                    top: -2px;
                    left: 0px;
                    right: 0px;
                    bottom: 0;
                    background-color: ${bgColor ? BG_COLORS[bgColor] : '#fff'};
                    border-radius: 22px 22px 0 0;
                    box-shadow: 0 -7px 15px 0 rgba(216, 206, 206, 0.2);
                    z-index: -1;
                }

                ${MenuSubItemContainer} {
                    visibility: visible;
                    opacity: 1;
                }
            `}
    }
`;

const NavBar = styled.div<{ bgColor?: HeaderBgColor; isSticky: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    padding: 0 12px;
    background-color: ${({ bgColor }) =>
        rgba(bgColor ? BG_COLORS[bgColor] : COLORS.white, 0.9)};

    backdrop-filter: saturate(180%) blur(5px);

    ${({ isSticky }) =>
        isSticky &&
        css`
            box-shadow: 0px -2px 10px 3px #ccc;
        `}

    @media ${device.laptopMedium} {
        display: none;
    }
`;

const Container = styled('header')<{ bgColor: HeaderBgColor }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 10;

    padding-top: 44px;
    background-color: ${({ bgColor }) =>
        bgColor ? BG_COLORS[bgColor] : '#fff'};

    @media ${device.laptopMedium} {
        padding-top: 24px;
    }

    & > div:nth-of-type(2) {
        z-index: 10;
    }
`;

const MenuContainer = styled.nav`
    display: flex;
    margin-left: 24px;
`;
